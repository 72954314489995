import {
    Alert,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
// import { update } from "cypress/types/lodash";
// import { use } from "chai";
// import { DeleteAction } from "../../api/IoT/actions";
import { useAddActionMutation, useDeleteActionMutation, useGetActionsByRuleIdQuery, useUpdateActionMutation } from "../../features/auth/actionsAPI";
import { useAddDeviceConditionMutation, useAddGroupConditionMutation, useAddRelativeTimeConditionMutation, useDeleteDeviceConditionMutation, useDeleteGroupConditionMutation, useDeleteRelativeTimeConditionMutation, useGetConditionsByRuleQuery, useUpdateDeviceConditionMutation, useUpdateRelativeTimeConditionMutation } from "../../features/auth/conditionsAPI";
import { useGetDevicesAndStatesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import AddAction from "./AddActions";
import AddConditions, { CONTINUOUS_DURATION, INSTANT_DURATION } from "./AddConditions";
import DeleteAction from "./DeleteAction";
import DeleteCondition from "./DeleteCondition";
import RuleIfDisplay from "./RuleIfDisplay";
import RuleThenDisplay from "./RuleThenDisplay";
import UpdateAction from "./UpdateAction";
import UpdateCondition from "./UpdateCondition";

export const ALERT_RULE_GROUP_EXISTING = 0;
export const ALERT_RULE_GROUP_CUSTOM = 1;

function Conditions({ ruleId, propertyId, isReadOnly }) {
    console.log({ isReadOnly });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [rule, setRule] = useState([]);
    const [timeConditions, setTimeConditions] = useState([]);
    const [deviceConditions, setDeviceConditions] = useState([]);
    const [groupConditions, setGroupConditions] = useState([]);

    // ruleConditionId, ruleId, deviceId, deviceStateId, isActive
    const [showAddCondition, setShowAddCondition] = useState(false);
    const [ruleConditionId, setRuleConditionId] = useState();
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasRefreshResponse, setHasRefreshResponse] = useState(false);
    const [successAlertMsg, setSuccessAlertMsg] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    // add device condition
    // const [devicesStates, setDevicesStates] = useState([]);
    // const [selectedDeviceId, setSelectedDeviceId] = useState();
    // const [selectedDeviceStateId, setSelectedDeviceStateId] = useState();
    // const [selectedStatesList, setSelectedStatesList] = useState([]);

    // Add Custom Condition
    const [bookingAlertLimit, setBookingAlertLimit] = useState(-1);
    const [minuteAlertLimit, setMinuteAlertLimit] = useState(-1);
    const [minuteAlertInterval, setMinuteAlertInterval] = useState(0);
    const [specificTimeValue, setSpecificTimeValue] = useState();

    const [days, setDays] = React.useState(() => []);

    const [timeCondition, setTimeCondition] = useState(
        {
            TimeValue: 0,
            relativeTime: 1,
            relative: "",
            checkin: "",
            RuleId: ruleId,
            isActive: true,
            BookingAlertLimit: bookingAlertLimit,
            MinuteAlertLimit: minuteAlertLimit,
            MinuteAlertTimeInterval: minuteAlertInterval,
            SpecificTime: specificTimeValue,
            RecurringDays: days,
            TimeSet: "",
        },
    );

    const [timeText, setTimeText] = useState("");

    // << rtk query
    const {
        data: dataGetConditionsByRule,
        isLoading: isLoadingGetConditionsByRule,
        isFetching: isFetchingGetConditionsByRule,
    } = useGetConditionsByRuleQuery({
        ruleId,
    });
    const {
        data: dataActionsByRuleId,
        isLoading: isLoadingActionsByRuleId,
        isFetching: isFetchingActionsByRuleId,
    } = useGetActionsByRuleIdQuery({
        ruleId,
    });

    const [addRelativeTimeCondition, {
        error: errorAddRelativeTimeCondition,
        isError: isErrorAddRelativeTimeCondition,
        isSuccess: isSuccessAddRelativeTimeCondition,
    }] = useAddRelativeTimeConditionMutation({
        fixedCacheKey: `add-relative-time-condition-${ruleId}`,
    });
    const [updateRelativeTimeCondition, {
        error: errorUpdateRelativeTimeCondition,
        isError: isErrorUpdateRelativeTimeCondition,
        isSuccess: isSuccessUpdateRelativeTimeCondition,
    }] = useUpdateRelativeTimeConditionMutation({
        fixedCacheKey: `update-time-conditions-${ruleId}`,
    });
    // triggers alert success message
    const [
        addGroupCondition, {
            error: errorAddGroupCondition,
            isError: isErrorAddGroupCondition,
            isSuccess: isSuccessAddGroupCondition,
        }] = useAddGroupConditionMutation({
        fixedCacheKey: `add-group-condition-${ruleId}`,
    });
    // triggers alert success message
    const [
        addDeviceCondtion, {
            error: errorAddDeviceCondition,
            isError: isErrorAddDeviceCondition,
            isSuccess: isSuccessAddDeviceCondition,
        }] = useAddDeviceConditionMutation({
        fixedCacheKey: `add-device-condition-${ruleId}`,
    });
    // triggers alert success message
    const [updateDeviceCondition, {
        error: errorUpdateDeviceCondition,
        isError: isErrorUpdateDeviceCondition,
        isSuccess: isSuccessUpdateDeviceCondition,
    }] = useUpdateDeviceConditionMutation({
        fixedCacheKey: `update-device-condition-${ruleId}`,
    });
    // triggers alert remove conditon success
    const [deleteDeviceCondition, {
        error: errorDeleteDeviceCondition,
        isError: isErrorDeleteDeviceCondition,
        isSuccess: isSuccessDeleteDeviceCondition,
    }] = useDeleteDeviceConditionMutation({
        fixedCacheKey: `delete-device-condition-${ruleId}`,
    });

    const [deleteRelativeTimeCondition, {
        error: errorDeleteRelativeTimeCondition,
        isError: isErrorDeleteRelativeTimeCondition,
        isSuccess: isSuccessDeleteRelativeTimeCondition,
    }] = useDeleteRelativeTimeConditionMutation({
        fixedCacheKey: `delete-relative-time-condition-${ruleId}`,
    });

    const [deleteGroupCondition, {
        error: errorDeleteGroupCondition,
        isError: isErrorDeleteGroupCondition,
        isSuccess: isSuccessDeleteGroupCondition,
    }] = useDeleteGroupConditionMutation({
        fixedCacheKey: `delete-group-condition-${ruleId}`,
    });

    const isGettingConditionsByRule = isLoadingGetConditionsByRule || isFetchingGetConditionsByRule;
    const isGettingActionsByRuleId = isLoadingActionsByRuleId || isFetchingActionsByRuleId;

    const [
        addAction, {
            error: errorAddActionMutation,
            isError: isErrorAddActionMutation,
            isSuccess: isSuccessAddActionMutation,
        }] = useAddActionMutation({
        fixedCacheKey: `add-action-${ruleId}`,
    });
    const [updateActionData, {
        error: errorUpdateAction,
        isError: isErrorUpdateAction,
        isSuccess: isSuccessUpdateAction,
    }] = useUpdateActionMutation({
        fixedCacheKey: `update-action-${ruleId}`,
    });
    const [
        deleteActionData, {
            error: errorDeleteAction,
            isError: isErrorDeleteAction,
            isSuccess: isSuccessDeleteAction,
        }] = useDeleteActionMutation({
        fixedCacheKey: `delete-action-${ruleId}`,
    });

    useEffect(() => {
        if (dataGetConditionsByRule !== undefined) {
            setRule(dataGetConditionsByRule.rule);
            setTimeConditions(dataGetConditionsByRule.relativeTimeConditions);
            setDeviceConditions(dataGetConditionsByRule.deviceConditions);
            setGroupConditions(dataGetConditionsByRule.groupConditions);
        }
    }, [dataGetConditionsByRule]);

    useEffect(() => {
        // console.log("isSuccessAddDeviceConditionMutation", ruleId, isSuccessAddDeviceConditionMutation);
        if (isSuccessAddRelativeTimeCondition) {
            setAlertMsg("The time condition has been successfully added.");
            setAlertSeverity("success");
        }
        if (isSuccessAddDeviceCondition) {
            setAlertMsg("The device condition has been successfully added.");
            setAlertSeverity("success");
        }
        if (isSuccessAddGroupCondition) {
            setAlertMsg("The alert group condition has been successfully added.");
            setAlertSeverity("success");
        }
    }, [isSuccessAddDeviceCondition,
        isSuccessAddRelativeTimeCondition,
        isSuccessAddGroupCondition]);

    useEffect(() => {
        if (isSuccessUpdateDeviceCondition) {
            setAlertMsg("This device condition has been successfully updated.");
            setAlertSeverity("success");
        }
        if (isSuccessUpdateRelativeTimeCondition) {
            setAlertMsg("This time condition has been successfully updated.");
            setAlertSeverity("success");
        }
    }, [isSuccessUpdateRelativeTimeCondition,
        isSuccessUpdateDeviceCondition]);

    useEffect(() => {
        if (isSuccessDeleteDeviceCondition
            || isSuccessDeleteGroupCondition
            || isSuccessDeleteRelativeTimeCondition) {
            setAlertMsg("Condition has been successfully deleted.");
            setAlertSeverity("success");
        }
    }, [isSuccessDeleteDeviceCondition,
        isSuccessDeleteGroupCondition,
        isSuccessDeleteRelativeTimeCondition]);

    useEffect(() => {
        const hasError = isErrorAddRelativeTimeCondition
            || isErrorUpdateRelativeTimeCondition
            || isErrorDeleteRelativeTimeCondition
            || isErrorAddGroupCondition
            || isErrorDeleteGroupCondition
            || isErrorAddDeviceCondition
            || isErrorUpdateDeviceCondition
            || isErrorDeleteDeviceCondition;

        let msg = "";

        if (isErrorAddRelativeTimeCondition) {
            msg = errorAddRelativeTimeCondition.data.message;
        }
        if (isErrorUpdateRelativeTimeCondition) {
            msg = errorUpdateRelativeTimeCondition.data.message;
        }
        if (isErrorDeleteRelativeTimeCondition) {
            msg = errorDeleteRelativeTimeCondition.data.message;
        }
        if (isErrorAddGroupCondition) {
            msg = errorAddGroupCondition.data.message;
        }
        if (isErrorDeleteGroupCondition) {
            msg = errorDeleteGroupCondition.data.message;
        }
        if (isErrorAddDeviceCondition) {
            msg = errorAddDeviceCondition.data.message;
        }
        if (isErrorUpdateDeviceCondition) {
            msg = errorUpdateDeviceCondition.data.message;
        }
        if (isErrorDeleteDeviceCondition) {
            msg = errorDeleteDeviceCondition.data.message;
        }
        if (hasError) {
            setAlertMsg(msg);
            setAlertSeverity("error");
        }
    }, [isErrorAddRelativeTimeCondition,
        isErrorUpdateRelativeTimeCondition,
        isErrorDeleteRelativeTimeCondition,
        isErrorAddGroupCondition,
        isErrorDeleteGroupCondition,
        isErrorAddDeviceCondition,
        isErrorUpdateDeviceCondition,
        isErrorDeleteDeviceCondition]);

    useEffect(() => {
        if (isSuccessAddActionMutation) {
            setAlertMsg("Action has been successfully added.");
            setAlertSeverity("success");
        }
    }, [isSuccessAddActionMutation]);

    useEffect(() => {
        if (isSuccessUpdateAction) {
            setAlertMsg("Action has been successfully updated.");
            setAlertSeverity("success");
        }
    }, [isSuccessUpdateAction]);

    useEffect(() => {
        if (isSuccessDeleteAction) {
            setAlertMsg("Action has been successfully deleted.");
            setAlertSeverity("success");
        }
    }, [isSuccessDeleteAction]);

    useEffect(() => {
        const hasError = isErrorAddActionMutation
        || isErrorUpdateAction
        || isErrorDeleteAction;
        let msg = "";

        if (isErrorAddActionMutation) {
            msg = errorAddActionMutation.data.message;
        }
        if (isErrorUpdateAction) {
            msg = errorUpdateAction.data.message;
        }

        if (isErrorDeleteAction) {
            msg = errorDeleteAction.data.message;
        }

        if (hasError) {
            setAlertMsg(msg);
            setAlertSeverity("error");
        }
    }, [isErrorAddActionMutation,
        isErrorUpdateAction,
        isErrorDeleteAction]);
    // >> rtk query

    // const [customRuleGroupDisableControl, setCustomRuleGroupDisableControl] = useState
    // const setDefaultValues = () => {
    //     setMinuteAlertLimit(-1);
    //     setBookingAlertLimit(-1);
    //     setMinuteAlertInterval(0);
    //     setisMinuteLimitEnabled(false);
    //     setisBookingLimitEnabled(false);
    // };

    useEffect(() => {
        setAlertMsg("");
        setAlertSeverity("");
    }, []);

    useEffect(() => {
        updateTimeText();
    }, [timeText]);

    // useEffect(() => {
    //     // (async () => {
    //     //     const rulesByProperty = await getRulesDefaultByProperty(propertyId);
    //     //     // console.log("rulesByProperty: ", rulesByProperty);
    //     //     rulesByProperty.sort((a, b) => b.ruleId - a.ruleId);
    //     //     setRules(rulesByProperty);
    //     // })();
    //     console.log("useffect.reload", reload);
    //     // GetConditionsHandler();
    //     // GetDevicesStates();
    //     // GetActionsHandler();
    //     setDefaultValues();
    // }, [reload]);

    // const DeleteConditionHandler = async (type, id) => {
    //     // console.log("DELETE");
    //     // console.log(type);
    //     // console.log(id);
    //     switch (type) {
    //         case "Device":
    //             await DeleteDeviceCondition(id);
    //             break;
    //         case "Time":
    //             await DeleteRelativeTimeCondition(id);
    //             break;
    //         case "Rule Group":
    //             await DeleteGroupCondition(id);
    //             break;
    //         default:
    //             break;
    //     }
    //     setSuccessAlertMsg("Condition has been successfully deleted.");
    //     setHasRefreshResponse(true);
    //     setRuleConditionId(id);
    //     setReload((curr) => !curr);
    // };

    // actions related
    // const [showAddAction, setShowAddAction] = useState(false);
    // const [actionsLoading, setActionsLoading] = useState(false);
    // const [actions, setActions] = useState([]);
    // const [selectedAction, setSelectedAction] = useState();
    // const [showEditAction, setShowEditAction] = useState(false);

    // const DeleteActionHandler = async (eventActionId) => {
    //     await DeleteAction(eventActionId);
    //     setReload((x) => !x);
    // };

    // const EditActionHandler = async (action) => {
    //     setSelectedAction(action);
    //     setShowEditAction(true);
    // };

    const updateTimeText = () => {
        if (timeCondition.relative && timeCondition.checkin && timeCondition.TimeValue) {
            setTimeText(`Notify me only ${timeCondition.TimeValue} minutes ${timeCondition.relative.toLowerCase()} ${timeCondition.checkin.toLowerCase()} if an alert is activated.`);
        } else {
            setTimeText("");
        }
    };

    const ruleIfTimeActions = (param) => {
        if (isReadOnly) return (<>&nbsp;</>);
        if (rule != null && (rule.isPreMadeRule && rule.isDefaultRule)) {
            return (
                <UpdateCondition
                    param={param}
                    propertyId={propertyId}
                    conditionType="PostCheckOut"
                    rule={rule}
                    ruleId={ruleId}
                    onClick={() => {
                        clearAlertMessage();
                    }}
                />
            );
        }

        if (rule != null && (!rule.isPreMadeRule || (!rule.isPreMadeRule && !rule.isDefaultRule))) {
            return (
                <>
                    <UpdateCondition
                        param={param}
                        propertyId={propertyId}
                        conditionType="Time"
                        rule={rule}
                        ruleId={ruleId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                    <DeleteCondition
                        rule={rule}
                        ruleId={ruleId}
                        conditionType="Time"
                        conditionId={param.timeConditionId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                </>
            );
        }

        if (rule != null && (!rule.isPreMadeRule || (rule.isPreMadeRule && rule.isDefaultRule))) {
            return (
                <>
                    <UpdateCondition
                        param={param}
                        propertyId={propertyId}
                        conditionType="Device"
                        rule={rule}
                        ruleId={ruleId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                    <DeleteCondition
                        rule={rule}
                        ruleId={ruleId}
                        conditionType="Device"
                        conditionId={param.deviceCondition.deviceConditionId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                </>
            );
        }

        return (<>&nbsp;</>);
    };
    const ruleIfDeviceActions = (param) => {
        if (isReadOnly) return (<>&nbsp;</>);
        if (rule != null && (!rule.isPreMadeRule || (rule.isPreMadeRule && rule.isDefaultRule))) {
            return (
                <>
                    <UpdateCondition
                        param={param}
                        propertyId={propertyId}
                        conditionType="Device"
                        rule={rule}
                        ruleId={ruleId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                    <DeleteCondition
                        rule={rule}
                        ruleId={ruleId}
                        conditionType="Device"
                        conditionId={param.deviceCondition.deviceConditionId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                </>
            );
        }

        return (<>&nbsp;</>);
    };

    const ruleIfGroupActions = (g) => {
        if (isReadOnly) return (<>&nbsp;</>);
        if (rule != null && !rule.isPreMadeRule) {
            return (
                <>
                    <IconButton
                        disabled
                        color="primary"
                        size="small"
                    //   onClick={(e) => EditConditionButtonHandler(
                    //       c.deviceId,
                    //       c.deviceStateId,
                    //       c.ruleConditionId,
                    //       c.isActive,
                    //   )}
                    >
                        <Edit />
                    </IconButton>
                    <DeleteCondition
                        rule={rule}
                        ruleId={ruleId}
                        conditionType="Rule Group"
                        conditionId={g.groupCondition.groupConditionId}
                        onClick={() => {
                            clearAlertMessage();
                        }}
                    />
                </>
            );
        }

        return (<>&nbsp;</>);
    };

    const ruleThenActions = (a) => {
        if (isReadOnly) return (<>&nbsp;</>);
        if (rule != null && !rule.isPreMadeRule) {
            return (
                <>
                    <UpdateAction
                        propertyId={propertyId}
                        ruleId={ruleId}
                        selectedAction={a}
                        onClick={() => clearAlertMessage()}
                    />
                    {/* <IconButton
                        color="error"
                        size="small"
                        onClick={() => {
                            DeleteActionHandler(a.eventActionId);
                        }}
                    >
                        <Delete />
                    </IconButton> */}
                    <DeleteAction
                        ruleId={ruleId}
                        eventActionId={a.eventActionId}
                        onClick={() => clearAlertMessage()}
                    />
                </>
            );
        }

        return (<>&nbsp;</>);
    };

    /*
    setTimeText(`Notify me only
    ${timeCondition.TimeValue} minutes
    ${timeCondition.relative.toLowerCase()}
    ${timeCondition.checkin.toLowerCase()} if an alert is activated.`);
    */

    const refreshResponse = () => (
        <Alert
            severity={alertSeverity}
            onClose={() => {
                setAlertMsg("");
                setAlertSeverity("");
            }}
            sx={{
                marginBottom: 2,
            }}
        >
            {alertMsg}
        </Alert>
    );

    const clearAlertMessage = () => {
        setAlertMsg("");
        setAlertSeverity("");
    };

    return (
        <>
            {alertMsg !== "" && alertSeverity !== "" && (
                <>
                    {refreshResponse()}
                </>
            )}
            <Stack
                direction="row"
            >
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    pt={theme.spacing(2)}
                    pr={theme.spacing(3)}
                >
                    If:
                </Typography>
                <List
                    dense
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {isGettingConditionsByRule && <ListItem><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></ListItem>}

                    {!isGettingConditionsByRule
                        && timeConditions.length > 0
                        && timeConditions.map((rt) => (
                            <ListItem
                                key={rt.timeConditionId}
                            >
                                <ListItemText>
                                    <RuleIfDisplay
                                        conditionType="time"
                                        name={String(rt.relativeTime)}
                                        value={String(rt.timeValue)}
                                        state=""
                                        isActive={rt.isActive}
                                        time={rt.time}
                                        days={rt.days}
                                    />
                                </ListItemText>
                                {ruleIfTimeActions(rt)}
                            </ListItem>
                        ))}

                    {!isGettingConditionsByRule && deviceConditions.length > 0
                        && deviceConditions.map((d) => (
                            <ListItem
                                key={d.deviceCondition.deviceConditionId}
                            >
                                <ListItemText>
                                    <RuleIfDisplay
                                        conditionType="device"
                                        name={d.device.displayName}
                                        state={d.stateName}
                                        isActive={d.deviceCondition.isActive}
                                        deviceDurationType={d.deviceCondition.duration > 0 ? CONTINUOUS_DURATION : INSTANT_DURATION}
                                        deviceDuration={d.deviceCondition.duration}
                                    />
                                </ListItemText>
                                {ruleIfDeviceActions(d)}
                            </ListItem>
                        ))}

                    {!isGettingConditionsByRule && groupConditions.length > 0
                        && groupConditions.map((g) => (
                            <ListItem
                                key={g.groupCondition.groupdConditionId}
                            >
                                <ListItemText>
                                    <RuleIfDisplay
                                        conditionType="group"
                                        name={g.ruleGroupName}
                                        state=""
                                        isActive={g.groupCondition.isActive}
                                    />
                                </ListItemText>
                                {ruleIfGroupActions(g)}
                            </ListItem>
                        ))}
                </List>
            </Stack>
            {!isGettingConditionsByRule && (!showAddCondition && !rule.isPreMadeRule) && !isReadOnly && (
                <AddConditions
                    ruleId={ruleId}
                    propertyId={propertyId}
                    onClick={() => {
                        setAlertMsg("");
                        setAlertSeverity("");
                    }}
                />
            )}

            {/* Dialog for Editing a device condition */}
            {/* {
                conditionType === "Device" && (
                    <Dialog
                        open={editMode}
                        // onClose={() => setEditMode(false)}
                        fullWidth
                        maxWidth="sm"
                        fullScreen={isMobile}
                    >
                        <DialogTitle>Edit Condition</DialogTitle>
                        <DialogContent>
                            {!loadingDialog && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <InputLabel id="devices-select">Device</InputLabel>
                                        <Select
                                            disabled={disableControls}
                                            fullWidth
                                            value={selectedDeviceId}
                                            size="small"
                                            onChange={(e) => {
                                                setSelectedDeviceId(e.target.value);
                                                const filtered = devicesStates.filter((x) => x.device.deviceId === e.target.value)[0].states;
                                                setSelectedStatesList(filtered);
                                                setSelectedDeviceStateId(filtered[0].deviceStateId);
                                            }}
                                        >
                                            {devicesStates.map((d) => (
                                                <MenuItem key={d.device.deviceId} value={d.device.deviceId}>{d.device.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {selectedStatesList != null && (
                                            <>
                                                <InputLabel id="states-select">State</InputLabel>
                                                <Select
                                                    disabled={disableControls}
                                                    fullWidth
                                                    value={selectedDeviceStateId}
                                                    // disabled={enabledStateSelect}
                                                    onChange={(e) => {
                                                        setSelectedDeviceStateId(e.target.value);
                                                    }}
                                                    size="small"
                                                >
                                                    {selectedStatesList.map((s) => (
                                                        <MenuItem value={s.deviceStateId}>{s.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}

                                    </Grid>
                                </Grid>
                            )}

                            {loadingDialog && (
                                <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                disabled={disableControls}
                                color="info"
                                onClick={() => setEditMode(false)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={disableControls}
                                loadingPosition="end"
                                endIcon={<Save />}
                                onClick={(e) => EditConditionHandler(e)}
                            >
                                <span>Update</span>
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            } */}

            <Stack
                direction="row"
            >
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    pt="19px"
                >
                    Then:
                </Typography>
                <List
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {/* <li>Send an SMS alert.</li> */}
                    {isGettingActionsByRuleId && <ListItem><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></ListItem>}
                    {!isGettingActionsByRuleId && !loading && dataActionsByRuleId.length > 0 && dataActionsByRuleId.map((a) => (
                        <ListItem
                            key={a.eventActionId}
                        >
                            <ListItemText>
                                <RuleThenDisplay
                                    action={a}
                                />
                            </ListItemText>
                            {ruleThenActions(a)}
                            {/* {rule != null && !rule.isPreMadeRule && (
                                <Grid
                                    item
                                    sx={{
                                        "&:hover": {
                                            "& > path,use": {
                                                fill: "#f00",
                                            },
                                        },
                                    }}
                                >
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    EditActionHandler(a);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    DeleteActionHandler(a.eventActionId);
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )} */}

                        </ListItem>
                    ))}
                </List>

            </Stack>
            {(!isGettingActionsByRuleId && !rule.isPreMadeRule && !isReadOnly)
                //     && (!showAddAction
                //  || !showEditAction=)
                && (
                    <>
                        {/* <LoadingButton
                        variant="contained"
                        onClick={() => setShowAddAction(true)}
                        fullWidth={isMobile}
                        sx={{
                            marginLeft: {
                                xs: 0,
                                md: theme.spacing(7),
                            },
                        }}
                    >
                        <Add />
                        {" "}
                        Add Action
                    </LoadingButton> */}
                        <AddAction
                            propertyId={propertyId}
                            ruleId={ruleId}
                            onClick={() => {
                                clearAlertMessage();
                            }}
                        />
                    </>
                )}
            {/* {
                showAddAction && (
                    <Actions
                        ruleId={ruleId}
                        showAddAction={showAddAction}
                        showEditAction={showEditAction}
                        setAlertMes={setAlertMsg}
                        setAlertSev={setAlertSeverity}
                        closeAddAction={() => {
                            setShowAddAction(false);
                            setReload((x) => !x);
                        }}
                        propertyId={propertyId}
                    />
                )
            } */}
            {/* {
                showEditAction && (
                    <Actions
                        ruleId={ruleId}
                        showAddAction={showAddAction}
                        showEditAction={showEditAction}
                        setAlertMes={setAlertMsg}
                        setAlertSev={setAlertSeverity}
                        closeEditAction={() => {
                            setShowEditAction(false);
                            setReload((x) => !x);
                        }}
                        propertyId={propertyId}
                        selectedAction={selectedAction}
                    />
                )
            } */}
        </>
    );
}

export default Conditions;
