import { useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Toolbar from "react-big-calendar/lib/Toolbar";
import "./react-big-calendar.css";
import CalendarToolbarICalData from "./CalendarToolbarICalData";

const localizer = momentLocalizer(moment);

class CalendarToolbar extends Toolbar {
    render() {
        return (
            <div className="rbc-toolbar" style={{ gap: "0.5em" }}>
                <div
                    className="rbc-toolbar-label"
                    style={{ padding: "0 0 0 0", textAlign: "left", flexGrow: 0 }}
                >
                    {this.props.label}
                </div>
                <div>
                    •
                </div>
                <div style={{ flexGrow: 1 }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default function BookingCalendarBase({ bookings, propertyId }) {
    const events = bookings.map((booking) => ({
        start: new Date(moment.utc(booking.checkInUTC)),
        end: new Date(moment.utc(booking.checkOutUTC)),
        title: booking.name,
    }));

    // const handleOnSelectEvent = (calendarEvent, syntheticEvent) => {};

    const { defaultDate } = useMemo(
        () => ({
            defaultDate: new Date(),
        }),
        [],
    );
    return (
        <Calendar
            localizer={localizer}
            events={events}
            defaultDate={defaultDate}
            defaultView="month"
            style={{ height: "400px", fontSize: "0.8em" }}
            allDayAccessor={() => false}
            showMultiDayTimes
            tooltipAccessor={(event) => event.title}
            // onSelectEvent={handleOnSelectEvent}
            selectable={false}
            views={["month"]}
            // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-props-no-spreading
            components={{ toolbar: (props) => <CalendarToolbar {...props}><CalendarToolbarICalData propertyId={propertyId} /></CalendarToolbar> }}
        />
    );
}
