import React, { useEffect, useState } from "react";
import {
    Edit,
    Delete,
    Add,
    Save,
    Refresh,
    MediationOutlined,
    ErrorOutline,
    Error,
    Menu,
    OpenInNew,
    Battery0Bar,
    Battery2Bar,
    Battery4Bar,
    Battery5Bar,
    BatteryFull,
    Battery1Bar,
    Battery3Bar,
} from "@mui/icons-material";
import {
    Modal, Box,
    Button,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Dialog,
    DialogTitle, DialogContent,
    Alert,
    DialogActions,
    Grid,
    AlertTitle,
    Typography,
    Stack,
    CircularProgress,
    Tooltip,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalkingLuggage, faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { DeviceUpdate } from "../../models/devices";
import PartnerListForm from "./PartnerListForm";
import { DOORSENSOR_DEVICETYPEID, getUserFriendlyDeviceTypes } from "./constants/DeviceTypes";
import { useLazyGetConditionsByRuleQuery } from "../../features/auth/conditionsAPI";
import { PROPERTY_DEVICE_STATUS_PENDING } from "./constants/PropertyDeviceStatus";
import { useDeletePropertyDeviceStateMutation, useDisableDeviceMutation, useGetDevicesAndStatesByPropertyIdQuery, useGetDevicesByPropertyIdQuery, useGetPropertyDeviceStatesByPropertyQuery, useLazyGetDevicesByPropertyIdQuery, useUpdateAllDeviceStatesMutation, useUpdateDeviceMutation } from "../../features/auth/devicesAPI";
import { useGetRuleGroupQuery, useLazyGetRulesByPropertyQuery, useUpdateRuleMutation } from "../../features/auth/rulesAPI";
import { useLazyGetActionsByRuleIdQuery } from "../../features/auth/actionsAPI";
import ClickableHelpButton from "../helper/ClickableHelpButton";
import CustomProgressAlertInfo from "../helper/CustomProgresAlertInfo";

function DevicesPageViewOnly({ propertyId }) {
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [openPartnerList, setOpenPartnerList] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState({
        displayName: "",
        floorNumber: 0,
    });

    const [editMode, setEditMode] = useState(false);
    const [updateStatesLoading, setUpdateStatesLoading] = useState(false);
    const [disableControls, setDisableControls] = useState(true);
    const [
        disableUpdateAllDeviceStatesBtn,
        setDisableUpdateAllDeviceStatesBtn,
    ] = useState(true);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertMsgSeverity, setAlertMsgSeverity] = useState("");
    const [pollingInterval, setPollingInterval] = useState(8000);
    const [
        triggerGetDevicesByPropertyId,
        {
            data: dataTriggerGetDevicesByProperty,
            isLoading: isLoadingTriggerGetDevicesByPropertyId,
            isFetching: isFetchingTriggerGetDevicesByPropertyId,
        },
    ] = useLazyGetDevicesByPropertyIdQuery();
    const {
        data: propertyDeviceStatesByProperty,
        isLoading: isLoadingGetPropertyDeviceStatesByProperty,
        isFetching: isFetchingGetPropertyDeviceStatesByProperty,
        isSuccess: isSuccessGetPropertyDeviceStatesByProperty,
    } = useGetPropertyDeviceStatesByPropertyQuery(
        {
            propertyId,
        },
        {
            pollingInterval,
            skipPollingIfUnfocused: true,
        },
    );
    const {
        data: dataDeviceStatesByProperty,
        isLoading: isLoadingGetDeviceStatesByPropertyId,
        isFetching: isFetchingGetDeviceStatesByPropertyId,
    } = useGetDevicesAndStatesByPropertyIdQuery(
        {
            propertyId,
        },
        {
            pollingInterval,
            skipPollingIfUnfocused: true,
        },
    );
    const {
        data: dataDevicesByProperty,
        isLoading: isLoadingGetDevicesByPropertyId,
        isFetching: isFetchingGetDevicesByPropertyId,
    } = useGetDevicesByPropertyIdQuery(
        {
            propertyId,
        },
        {
            pollingInterval,
            refetchOnMountOrArgChange: true,
            skipPollingIfUnfocused: true,
        },
    );
    const [
        deletePropertyDeviceState,
        {
            data: deletedPropertyDeviceState,
            message: addedDeletedPropertyDeviceState,
            error: errorDeletedPropertyDeviceState,
            isError: isErrorDeletedPropertyDeviceState,
            isSuccess: isSuccessDeletedPropertyDeviceState,
        },
    ] = useDeletePropertyDeviceStateMutation();
    const [
        disableDevice,
        {
            data: dataDisableDevice,
            error: errorDisableDevice,
            isError: isErrorDisableDevice,
            isSuccess: isSuccessDisableDevice,
            isLoading: isLoadingDisableDevice,
        },
    ] = useDisableDeviceMutation();
    const [
        updateDevice,
        {
            data: dataUpdateDevice,
            error: errorUpdateDevice,
            isError: isErrorUpdateDevice,
            isSuccess: isSuccessUpdateDevice,
            isLoading: isLoadingUpdateDevice,
        },
    ] = useUpdateDeviceMutation();
    const [
        updateAllDeviceStates,
        {
            error: errorUpdateAllDeviceStates,
            isError: isErrorUpdateAllDeviceStates,
            isSuccess: isSuccessUpdateAllDeviceStates,
            isLoading: isLoadingUpdateAllDeviceStates,
        },
    ] = useUpdateAllDeviceStatesMutation();
    const [
        updateRule,
    ] = useUpdateRuleMutation();
    const [triggerGetRulesByProperty] = useLazyGetRulesByPropertyQuery();
    const [triggerGetConditionsByRule] = useLazyGetConditionsByRuleQuery();
    const [triggerGetActionsByRule] = useLazyGetActionsByRuleIdQuery();
    const {
        data: dataRuleGroup,
        isLoading: isLoadingGetRuleGroup,
        isFetching: isFetchingGetRuleGroup,
    } = useGetRuleGroupQuery(
        {
            propertyId,
        },
        {
            pollingInterval,
            skipPollingIfUnfocused: true,
        },
    );
    const configureAlertBox = (msg, severity) => {
        setAlertMsgSeverity(severity);
        setAlertMsg(msg);
    };

    useEffect(() => {
        setLoading(isFetchingTriggerGetDevicesByPropertyId);
        setRefreshLoading(isFetchingTriggerGetDevicesByPropertyId);
    }, [isFetchingTriggerGetDevicesByPropertyId]);
    useEffect(() => {
        setLoading(isFetchingGetDevicesByPropertyId || isFetchingGetPropertyDeviceStatesByProperty);
    }, [isFetchingGetDevicesByPropertyId, isFetchingGetPropertyDeviceStatesByProperty]);

    useEffect(() => {
        if (
            isSuccessDeletedPropertyDeviceState
            && deletedPropertyDeviceState !== undefined
        ) {
            setFailedDevicesError(false);
        }
    }, [isSuccessDeletedPropertyDeviceState]);

    useEffect(() => {
        if (dataDevicesByProperty !== undefined && propertyDeviceStatesByProperty !== undefined) {
            setFailedDevices(
                structuredClone(
                    propertyDeviceStatesByProperty.failedDevices,
                ),
            );
            setFailedDevicesError(
                propertyDeviceStatesByProperty.failedDevices.length > 0,
            );

            if (propertyDeviceStatesByProperty.failedDevices.length > 0) configureAlertBox("", "");
            setDevices(structuredClone(dataDevicesByProperty));
            if (propertyDeviceStatesByProperty.pendingDevices.length === 0) {
                setDisableUpdateAllDeviceStatesBtn(false);
                if (
                    dataDevicesByProperty.filter(
                        (d) => d.propertyDeviceStatus === 0,
                    ).length === 0
                    && dataDevicesByProperty.length === propertyDeviceStatesByProperty
                        .successfulDevices.length
                ) {
                    setPollingInterval(0);
                    console.log("polling 0 no pending in devices and same lengths");
                    setTimeout(() => {
                        configureAlertBox("", "");
                    }, 8000);
                }
            } else {
                setDisableUpdateAllDeviceStatesBtn(true);
                if (dataDevicesByProperty.filter(
                    (d) => d.propertyDeviceStatus === 0,
                ).length > 0
                ) {
                    setTimeout(() => {
                        configureAlertBox("", "");
                    }, 8000);
                }
                setLoading(!dataDevicesByProperty.filter((d) => d.propertyDeviceStatus === 0).length > 0);
                setPollingInterval(8000);
            }
        }
    }, [dataDevicesByProperty, propertyDeviceStatesByProperty]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: blue[500],
            },
            secondary: {
                // This is green.A700 as hex.
                main: grey[500],
            },
        },
    });

    useEffect(() => {
        configureAlertBox("", "");
        if (isErrorUpdateAllDeviceStates && errorUpdateAllDeviceStates !== undefined) {
            configureAlertBox(errorUpdateAllDeviceStates.message, "error");
        }
        if (isSuccessUpdateAllDeviceStates) {
            configureAlertBox(
                "Updated all device states. Check your phone for alerts.",
                "success",
            );
        }
    }, [isSuccessUpdateAllDeviceStates,
        isErrorUpdateAllDeviceStates]);
    useEffect(() => {
        if (isSuccessUpdateDevice && dataUpdateDevice !== undefined) {
            (async () => {
                await triggerGetRulesByProperty({ propertyId }).then(async (res) => {
                    res.data.forEach(async (rule) => {
                        await triggerGetConditionsByRule({ ruleId: rule.ruleId });
                        await triggerGetActionsByRule({ ruleId: rule.ruleId });
                    });
                });
            })();

            setPollingInterval(3000);
            setTimeout(() => {
                setEditMode(false);
                setDisableControls(false);
                configureAlertBox(dataUpdateDevice.message, "success");
            }, 3000);
        }
        if (isErrorUpdateDevice && errorUpdateDevice !== undefined) {
            configureAlertBox(errorUpdateDevice.message, "error");
            setEditMode(false);
            setDisableControls(false);
        }
    }, [isSuccessUpdateDevice,
        isErrorUpdateDevice]);

    useEffect(() => {
        if (isSuccessDisableDevice && dataDisableDevice !== undefined) {
            configureAlertBox(dataDisableDevice.message, "success");
            (async () => {
                await triggerGetRulesByProperty({ propertyId }).then((rules) => {
                    rules.data.forEach(async (r) => {
                        await triggerGetConditionsByRule({ ruleId: r.ruleId });
                        await triggerGetActionsByRule({ ruleId: r.ruleId });
                    });
                });
            })();
            setDisableControls(false);
        }

        if (isErrorDisableDevice && errorDisableDevice !== undefined) {
            configureAlertBox(errorDisableDevice.message, "error");
            setDisableControls(false);
        }
    }, [isSuccessDisableDevice, isErrorDisableDevice]);

    const UpdateAllDevicesStatesHandler = async () => {
        setUpdateStatesLoading(true);
        await updateAllDeviceStates({ propertyId });
        setUpdateStatesLoading(false);
    };

    const [failedDevicesError, setFailedDevicesError] = useState(false);
    const [failedDevices, setFailedDevices] = useState([]);

    const handleRefreshPartnerList = () => {
        setOpenPartnerList(false);
    };

    const handleCloseListofPartnersModal = () => {
        setOpenPartnerList(false);
    };

    const handleCheckInOutDoorSwitch = async (deviceId, displayName, isCheckInOutDoor, floorNumber) => {
        configureAlertBox(`Currently updating check-in/out door sensor: ${displayName}.`, "info");
        setDisableUpdateAllDeviceStatesBtn(true);
        await updateDevice({
            device: new DeviceUpdate(
                deviceId,
                null,
                displayName,
                true,
                !isCheckInOutDoor,
                floorNumber,
                propertyId,
            ),
        });
        setDisableUpdateAllDeviceStatesBtn(false);
    };

    const setDeviceState = (row) => {
        if (row.propertyDeviceStatus === PROPERTY_DEVICE_STATUS_PENDING) {
            return <Typography variant="p" color="#ff0000">Processing</Typography>;
        }
        if (row.deviceStateValue === null || row.stateName === "off") {
            return row.stateName;
        }

        return `${row.stateName} (${row.deviceStateValue.toFixed(1)}°${row.deviceStateUnitReference.unit
        })`;
    };

    const deviceStatus = () => {
        if (alertMsg !== "") {
            if (alertMsgSeverity === "info") {
                return (
                    <CustomProgressAlertInfo
                        message={alertMsg}
                    />
                );
            }
            return (
                <Alert
                    severity={alertMsgSeverity}
                    onClose={() => {
                        setAlertMsg("");
                        setAlertMsgSeverity("");
                    }}
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {alertMsg}
                </Alert>
            );
        }
        return null;
    };
    const getBatteryIcon = (batteryLevel) => {
        if (batteryLevel === 0) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery0Bar color="error" />
                </Tooltip>
            );
        }
        if (batteryLevel <= 20 && batteryLevel > 0) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery1Bar color="warning" />
                </Tooltip>
            );
        }
        if (batteryLevel <= 40 && batteryLevel > 20) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery2Bar color="warning" />
                </Tooltip>
            );
        }
        if (batteryLevel <= 60 && batteryLevel > 40) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery3Bar color={batteryLevel <= 50 ? "warning" : "red"} />
                </Tooltip>
            );
        }
        if (batteryLevel <= 80 && batteryLevel > 60) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery4Bar />
                </Tooltip>
            );
        }
        if (batteryLevel < 100 && batteryLevel > 80) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <Battery5Bar />
                </Tooltip>
            );
        }
        if (batteryLevel === 100) {
            return (
                <Tooltip title={`${batteryLevel}%`}>
                    <BatteryFull color="success" />
                </Tooltip>
            );
        }

        return ("");
    };

    return (
        <>
            {deviceStatus()}
            {/* {alertMsg !== "" && alertMsgSeverity !== "" && (
                <Alert
                    severity={alertMsgSeverity}
                    onClose={() => {
                        setAlertMsg("");
                        setAlertMsgSeverity("");
                    }}
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {alertMsg}
                </Alert>
            )} */}
            {failedDevicesError && (
                <Alert
                    severity="error"
                    onClose={async () => {
                        await deletePropertyDeviceState({ propertyId });
                    }}
                >
                    <AlertTitle>
                        Failed to add the following devices due to an unexpected error:
                    </AlertTitle>
                    {failedDevices.map((item) => (
                        <Typography component="li" key={item.propertyDeviceStateId}>
                            {item.deviceDisplayName}
                            &nbsp; (
                            {getUserFriendlyDeviceTypes(item.deviceTypeId)}
                            )
                        </Typography>
                    ))}
                    {/* <Typography style={{ fontWeight: 500 }}>You may try again using the &quot;Add Device&quot; button below.</Typography> */}
                </Alert>
            )}
            <Stack
                direction="row"
                sx={{ marginBottom: "4px" }}
            >
                <Button
                    endIcon={<OpenInNew />}
                    onClick={() => navigate(`/property/${propertyId}/update-devices`)}
                >
                    Devices
                </Button>
                <Button
                    endIcon={<OpenInNew />}
                    onClick={() => navigate(`/property/${propertyId}/update-rules`)}
                >
                    Alerts
                </Button>
                {/* <IconButton onClick={() => navigate(`/property/${propertyId}/update-devices`)}>
                    <OpenInNew />
                </IconButton> */}
            </Stack>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            {/* <TableCell>Device Partner</TableCell> */}
                            {/* <TableCell>ID</TableCell> */}
                            <TableCell>State</TableCell>
                            {/* <TableCell>Floor</TableCell> */}
                            <TableCell>Battery</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={30}
                                            width="inherit"
                                        >
                                            Loading...Please Wait
                                        </Skeleton>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && devices.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No devices found.
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading
                            && devices.length > 0
                            && devices.map((row) => (
                                <TableRow key={row.externalId}>
                                    {row.deviceTypeId === DOORSENSOR_DEVICETYPEID ? (
                                        <TableCell>
                                            {row.isCheckInOutDoor && (
                                                <Typography variant="p" color="success.main">Check In/Out Door</Typography>
                                            )}
                                        </TableCell>
                                    ) : (
                                        <TableCell />
                                    )}
                                    <TableCell>{row.displayName}</TableCell>
                                    {/* <TableCell>{row.externalId}</TableCell> */}
                                    <TableCell>{setDeviceState(row)}</TableCell>
                                    {/* <TableCell>
                                        {row.floorNumber !== 0 ? row.floorNumber : "none"}
                                    </TableCell> */}
                                    <TableCell>
                                        {getBatteryIcon(row.battery)}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DevicesPageViewOnly;
