import { Box, CircularProgress, Skeleton, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetPropertyStatusQuery } from "../../features/auth/hostAPI";

export default function PropertyStatus({ propertyId }) {
    const theme = useTheme();
    const [status, setStatus] = useState(null);
    const [statusCode, setStatusCode] = useState(0);
    const {
        data: dataPropertyStatus,
        status: statusPropertyStatus,
        refetch: refetchPropertyStatus,
    } = useGetPropertyStatusQuery({
        propertyId,
    }, {
        pollingInterval: 30000,
    });

    useEffect(() => {
        refetchPropertyStatus();
    }, []);

    useEffect(() => {
        if (dataPropertyStatus !== undefined) {
            setStatus(dataPropertyStatus.propertyStatusName);
            setStatusCode(dataPropertyStatus.propertyStatusId);
        }
    }, [dataPropertyStatus]);

    // TODO: setup colors for other statuses
    const statusColors = {
        0: { // vacant
            borderColor: theme.palette.property_status.vacant,
            backgroundColor: theme.palette.property_status.vacant_light,
        },
        1: { // reserved
        },
        2: { // occupied
            borderColor: theme.palette.property_status.occupied,
            backgroundColor: theme.palette.property_status.occupied_light,
        },
        3: { // maintenance due
        },
        4: { // maintenance ongoing
        },
        5: { // vacant, maintenance due
        },
        6: { // vacant, maintenance ongoing
        },
        7: { // occuppied, maintenance due
        },
        8: { // occupied, maintenance ongoing
        },
    };
    const borderColor = statusColors[statusCode].borderColor || undefined; // undefined will set to default colors
    const backgroundColor = statusColors[statusCode].backgroundColor || undefined; // undefined will set to default colors

    if (status === null) {
        return (
            <Skeleton
                width={theme.spacing(10)}
                height={theme.spacing(3)}
            />
        );
    }

    return (
        <Stack
            direction="row"
            spacing={2}
        >
            <Box
                sx={{
                    outline: "1px solid",
                    outlineColor: borderColor,
                    backgroundColor,
                    display: "inline-block",
                    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
                    borderRadius: 1,
                    color: borderColor,
                }}
            >
                {status}
            </Box>
            {statusPropertyStatus === "pending" && (
                <CircularProgress
                    size={theme.spacing(3)}
                />
            )}
        </Stack>
    );
}
