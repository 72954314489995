import { Button, TextField, Grid, NativeSelect, FormControl, InputLabel, Alert } from "@mui/material";
import moment from "moment-timezone";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { useDispatch } from "react-redux";
import { showLoader } from "../../features/auth/authSlice";
import CustomTimeField from "../helper/CustomTimeField";
import { useAddICalendarMutation, useUpdateICalendarMutation } from "../../features/auth/icalendarAPI";

function convertToSlug(text) {
    return text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
}

export function FormInputText({ name, control, label }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => <TextField fullWidth size="small" onChange={onChange} value={value} label={label} />}
        />
    );
}

export function FormInputSelect({ name, control, label, children }) {
    return (
        <FormControl fullWidth>
            <InputLabel htmlFor={`${convertToSlug(label)}-label`}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <NativeSelect
                        onChange={onChange}
                        value={value}
                        inputProps={{
                            id: `${convertToSlug(label)}-label`,
                        }}
                    >
                        {children}
                    </NativeSelect>
                )}
            />
        </FormControl>
    );
}

export function FormInputTime({ name, control, label }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <CustomTimeField
                    format="h:mm A"
                    name={name}
                    onChange={onChange}
                    value={value}
                    label={label}
                    localeText={{
                        timePickerToolbarTitle: `Select ${label}`,
                    }}
                />
            )}
        />
    );
}

const UpdateICalendar = forwardRef(({
    propertyFK,
    iCalendar,
    timeZone,
    standardCheckInTime,
    standardCheckOutTime,
    setShowICalForm,
    callbackRequired,
    callbackStatus,
    showCancelButton,
    callbackSaveSuccess,
}, ref) => {
    const dispatch = useDispatch();
    const [error, setError] = useState({ open: false, message: "" });

    const [
        addICalendar, {
            data: dataAddICalendar,
            isLoading: isLoadingAddICalendar,
            isFetching: isFetchingAddICalendar,
            isError: isErrorAddICalendar,
            isSuccess: isSuccessAddICalendar,
            error: errorAddICalendar,
        },
    ] = useAddICalendarMutation();

    const isAddingICalendar = isLoadingAddICalendar || isFetchingAddICalendar;

    const [
        updateICalendar, {
            data: dataUpdateICalendar,
            isLoading: isLoadingUpdateICalendar,
            isFetching: isFetchingUpdateICalendar,
            isError: isErrorUpdateICalendar,
            isSuccess: isSuccessUpdateICalendar,
            error: errorUpdateICalendar,
        },
    ] = useUpdateICalendarMutation();

    const isUpdatingICalendar = isLoadingUpdateICalendar || isFetchingUpdateICalendar;

    const hasError = isErrorAddICalendar || isErrorUpdateICalendar;

    useEffect(() => {
        let loaderMsg = "";
        if (isAddingICalendar) loaderMsg = "Adding";
        else loaderMsg = "Updating";

        if (isAddingICalendar || isUpdatingICalendar) {
            dispatch(showLoader({ open: true, message: `${loaderMsg} booking schedule...` }));
        } else {
            dispatch(showLoader({ open: false, message: "" }));
        }
    }, [isAddingICalendar, isUpdatingICalendar]);

    useEffect(() => {
        if (hasError) {
            let err = "";
            if (errorAddICalendar !== undefined) err = errorAddICalendar;
            if (errorUpdateICalendar !== undefined) err = errorUpdateICalendar;

            setError((prev) => ({
                ...prev,
                open: true,
                message: err.message,
            }));
        }
    }, [hasError, errorAddICalendar, errorUpdateICalendar]);

    useEffect(() => {
        if (isSuccessAddICalendar || isSuccessUpdateICalendar) {
            if (callbackSaveSuccess !== undefined) { callbackSaveSuccess(true); } // else { navigate(0); }
            if (callbackStatus !== undefined) { callbackStatus({ status: "success", message: "Booking schedule has been successfuly updated." }); }
        }
    }, [isSuccessAddICalendar, isSuccessUpdateICalendar]);

    // get list of timezone names
    const timeZoneNames = useMemo(() => {
        const tzNames = moment.tz.names();
        const tzNamesWithOffset = tzNames.map((tzName) => {
            const offset = moment.tz(tzName).format("Z");
            return {
                name: tzName,
                label: `(UTC${offset}) ${tzName}`,
            };
        });
        const sortByZone = (a, b) => {
            const [ahh, amm] = a.label.split("UTC")[1].split(")")[0].split(":");
            const [bhh, bmm] = b.label.split("UTC")[1].split(")")[0].split(":");
            return (+ahh * 60 + amm) - (+bhh * 60 + bmm);
        };
        const sortedTzNamesWithOffset = tzNamesWithOffset.sort(sortByZone);
        const sortedTzNamesWithOffsetAndDefault = ([{ name: "", label: "" }]).concat(sortedTzNamesWithOffset);
        return sortedTzNamesWithOffsetAndDefault;
    }, []);

    const { handleSubmit, control } = useForm({
        defaultValues: {
            url: iCalendar?.url || "",
            checkInTimeLocal: moment(iCalendar?.checkInTimeLocal || standardCheckInTime, "HH:mm:ss"),
            checkOutTimeLocal: moment(iCalendar?.checkOutTimeLocal || standardCheckOutTime, "HH:mm:ss"),
            timeZoneDatabaseName: iCalendar?.timeZoneDatabaseName || timeZone,
        },
    });
    const onSubmit = (data) => {
        const isUpdate = iCalendar !== null && iCalendar !== undefined;
        setError((prev) => ({
            ...prev,
            open: false,
        }));

        const body = {
            ...data,
            propertyFK,
            checkInTimeLocal: moment(data.checkInTimeLocal).format("HH:mm:ss"),
            checkOutTimeLocal: moment(data.checkOutTimeLocal).format("HH:mm:ss"),
        };

        if (isUpdate) {
            updateICalendar({
                body,
                iCalerndarId: iCalendar.id,
            });
        } else {
            addICalendar({
                body,
            });
        }

        // dispatch(showLoader({ open: true, message: `${isUpdate ? "Updating" : "Adding"} booking schedule...` }));
        // const apiCall = isUpdate
        //     ? API.put("serverlessICalendarAPI", "/id", {
        //         body,
        //         queryStringParameters: { id: iCalendar.id },
        //     })
        //     : API.post("serverlessICalendarAPI", "/new", {
        //         body,
        //     });

        // apiCall.then(() => {
        //     dispatch(showLoader({ open: false, message: "" }));
        //     if (callbackRequired !== undefined) { callbackRequired(); } // else { navigate(0); }
        //     if (callbackStatus !== undefined) { callbackStatus({ status: "success", message: "Booking schedule has been successfuly updated." }); }
        // }).catch((err) => {
        //     dispatch(showLoader({ open: false, message: "" }));
        //     const validationErrorsObj = err.response.data?.errors || {};
        //     // const validationErrors = Object.keys(validationErrorsObj).map((key) => validationErrorsObj[key].join(", "));
        //     const validationErrors = Object.keys(validationErrorsObj).map((key) => (key === "Url" ? "Enter a valid ICalendar URL." : validationErrorsObj[key].join(", ")));
        //     const validationErrorsString = validationErrors.join(", ");
        //     setError((prev) => ({
        //         ...prev,
        //         open: true,
        //         message: `An error occured: ${validationErrorsString || err.response.data?.message || err.message || "Unknown error"}`,
        //     }));
        // });
    };

    const icalLinkInput = <FormInputText name="url" control={control} label="iCalendar link" />;
    const checkInTimeInput = <FormInputTime name="checkInTimeLocal" control={control} label="Check In Time" />;
    const checkOutTimeInput = <FormInputTime name="checkOutTimeLocal" control={control} label="Check Out Time" />;
    const timeZoneSelect = (
        <FormInputSelect name="timeZoneDatabaseName" control={control} label="Time Zone Name">
            {timeZoneNames.map((tzName) => (
                <option key={tzName.name} value={tzName.name}>
                    {tzName.label}
                </option>
            ))}
        </FormInputSelect>
    );

    if (callbackSaveSuccess !== undefined) {
        return (
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {icalLinkInput}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {checkInTimeInput}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {checkOutTimeInput}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {timeZoneSelect}
                    </Grid>
                    {error.open && (
                        <Grid item xs={12}>
                            <Alert
                                severity="error"
                                onClose={() => setError((prev) => ({
                                    ...prev,
                                    open: false,
                                }))}
                            >
                                {error.message}

                            </Alert>
                        </Grid>
                    )}
                </Grid>
                <Button ref={ref} type="submit" onClick={handleSubmit(onSubmit)} />
            </form>
        );
    }

    return (
        <form>
            <Grid container spacing={3}>
                {error.open && (
                    <Grid item xs={12}>
                        <Alert
                            severity="error"
                            onClose={() => setError((prev) => ({
                                ...prev,
                                open: false,
                            }))}
                        >
                            {error.message}

                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {icalLinkInput}
                </Grid>
                <Grid item xs={6} md={4}>
                    {checkInTimeInput}
                </Grid>
                <Grid item xs={6} md={4}>
                    {checkOutTimeInput}
                </Grid>
                <Grid item xs={12} md={4}>
                    {timeZoneSelect}
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Button
                        style={{ width: "30%", float: "right" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Update
                    </Button>
                    {(showCancelButton) && (
                        <Button
                            style={{ width: "30%", float: "right", marginRight: "10px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                if (setShowICalForm) { setShowICalForm(false); }
                            }}
                        >
                            Go Back
                        </Button>
                    )}

                </Grid>
            </Grid>
        </form>
    );
});

export default UpdateICalendar;
