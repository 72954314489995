import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLazySearchLocationQuery } from "../../features/auth/hostAPI";

export default function CustomLocationFormGroup({
    propertyInfo,
    callbackResult,
}) {
    // const [PropertyAddress, setPropertyAddress] = useState("");
    const [addressforSearch, setAddressforSearch] = useState("");
    const [searchLocation, setSearchLocation] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");

    const mapInitialState = {
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    };

    const [mapLocation, setMapLocation] = useState(mapInitialState);

    const resultInitialState = {
        PropertyAddress: "",
        mapLocation: mapInitialState,
    };

    const [result, setResult] = useState(resultInitialState);

    const [triggerSearchLocation, {
        // data: dataSearchLocation,
        isLoading: isLoadingSearchLocation,
        isFetching: isFetchingSearchLocation,
    }] = useLazySearchLocationQuery();

    const [geoSearchResult, setGeoSearchResult] = useState([]);

    useEffect(() => {
        if (propertyInfo !== undefined) {
            setAddressforSearch(propertyInfo.streetAddress1);
            setMapLocation({
                ...mapLocation,
                latitude: parseFloat(propertyInfo.locationLat),
                longitude: parseFloat(propertyInfo.locationLong),
                country: propertyInfo.country,
                municipality: propertyInfo.city,
                postalCode: propertyInfo.postalCode,
                region: propertyInfo.state,
                timeZone: propertyInfo.timeZone,
            });

            setResult((prev) => ({
                ...prev,
                PropertyAddress: propertyInfo.streetAddress1,
                mapLocation: {
                    latitude: parseFloat(propertyInfo.locationLat),
                    longitude: parseFloat(propertyInfo.locationLong),
                    country: propertyInfo.country,
                    municipality: propertyInfo.city,
                    postalCode: propertyInfo.postalCode,
                    region: propertyInfo.state,
                    timeZone: propertyInfo.timeZone,
                },
            }));
        }
    }, [propertyInfo]);

    useEffect(() => {
        // if (result.PropertyAddress.trim().length > 0) {
        if (callbackResult !== undefined) callbackResult(result);
        // }
    }, [result]);

    const isSearchLocationLoading = isLoadingSearchLocation || isFetchingSearchLocation;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Autocomplete
                    options={geoSearchResult}
                    getOptionLabel={(option) => option.label || ""}
                    noOptionsText=""
                    loading={isSearchLocationLoading}
                    filterOptions={(x) => x}
                    autoHighlight
                    size="small"
                    renderInput={((params) => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Street Address"
                            value={addressforSearch}
                        />
                    ))}
                    inputValue={addressforSearch}
                    onInputChange={async (evt, val, res) => {
                        if (val.trim().length !== 0) { setAddressforSearch(val); }
                        if (res === "input") {
                            setAddressforSearch(val);
                            if (val.trim().length >= 3) {
                                const data = await triggerSearchLocation({
                                    locationVal: val,
                                });

                                setGeoSearchResult(data.data);
                            }
                        } else if (res === "clear") {
                            setAddressforSearch(val);
                            setMapLocation(mapInitialState);
                        }
                    }}
                    value={selectedLocation}
                    onChange={(evt, val) => {
                        setSelectedLocation(val);
                        if (val) {
                            setMapLocation(val);
                            setResult((prev) => ({
                                ...prev,
                                PropertyAddress: val.street !== undefined ? val.street : "",
                                mapLocation: val,
                            }));
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}
