import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UpdateICalendar from "./UpdateICalendar";
import { useGetICalendarQuery } from "../../features/auth/icalendarAPI";
import { useGetPropertyQuery } from "../../features/auth/hostAPI";

export default function UpdateSchedulePage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        data: dataGetProperty,
    } = useGetPropertyQuery({ propertyId: id });

    const {
        data: dataICalendar,
        isLoading: isLoadingICalendar,
        isFetching: isFetchingICalendar,
    } = useGetICalendarQuery({
        propertyFk: id,
    });

    return (
        <Card>
            {
                !isLoadingICalendar ? (
                    <>
                        <CardHeader title={`Update Schedule${dataGetProperty && ` for ${dataGetProperty.propertyName}`}`} />
                        <CardContent>
                            <UpdateICalendar
                                propertyFK={id}
                                iCalendar={dataICalendar}
                                callbackSuccess={() => {
                                    navigate(`/property/${id}`);
                                }}
                                showCancelButton
                                setShowICalForm={(willShow) => {
                                    if (willShow) {
                                        navigate(`/property/${id}/update-schedule`);
                                    } else {
                                        navigate(`/property/${id}`);
                                    }
                                }}
                            />
                        </CardContent>
                    </>
                ) : (
                    <>
                        <CardHeader title={(
                            <Skeleton
                                variant="rectangular"
                                sx={{ fontSize: "2rem" }}
                            />
                        )}
                        />
                        <CardContent>
                            <Skeleton
                                variant="rectangular"
                                height={209.5}
                            />
                        </CardContent>
                    </>
                )
            }
        </Card>
    );
}
