import { Info, Menu, OpenInNew } from "@mui/icons-material";
import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useGetBookingsQuery, useGetICalendarQuery, usePullBookingsMutation } from "../../features/auth/icalendarAPI";

export default function CalendarToolbarICalData({ propertyId }) {
    const navigate = useNavigate();
    const {
        data: dataICalendar,
        isLoading: isLoadingICalendar,
        isFetching: isFetchingICalendar,
        refetch: refetchScheduleInfo,
    } = useGetICalendarQuery({
        propertyFk: propertyId,
    });

    const isGettingICalendar = isLoadingICalendar || isFetchingICalendar;

    const {
        isFetching: isFetchingGetBookings,
    } = useGetBookingsQuery({ iCalendarFK: dataICalendar?.id }, { skip: !dataICalendar });

    const [pullBookings, {
        // data: dataPullBookings,
        isLoading: isLoadingPullBookings,
        isFetching: isFetchingPullBookings,
        error: errorPullBookings,
    }] = usePullBookingsMutation();

    const offset = moment.tz(dataICalendar?.timeZoneDatabaseName).format("Z");
    const timeZoneFullName = `(UTC${offset}) ${dataICalendar?.timeZoneDatabaseName}`;

    const onRefreshBookings = async () => {
        await pullBookings({ iCalendarFK: dataICalendar?.id }).unwrap();
    };

    const scheduleInfo = () => (
        <Button
            endIcon={<OpenInNew />}
            onClick={() => navigate(`/property/${propertyId}/update-schedule`)}
        >
            {timeZoneFullName}
        </Button>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end", alignItems: "center" }}>
            <Box sx={{ flexGrow: 1 }}>
                {isGettingICalendar ? (
                    <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                ) : (
                    scheduleInfo()
                )}
            </Box>
            {/* {dataICalendar?.lastSyncedAt > dataICalendar?.createdAt && (
                <Box
                    sx={{
                        border: "1px solid",
                        borderColor: "info.main",
                        borderRadius: 1,
                        backgroundColor: "white",
                        paddingY: 1,
                        paddingX: 2,
                        color: "info.main",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Info fontSize="medium" sx={{ marginRight: 2 }} />
                    <Box>
                        {`Last synced ${moment(dataICalendar?.lastSyncedAt).utc().fromNow()}`}
                    </Box>
                </Box>
            )}
            {dataICalendar !== undefined ? (
                <Box>
                    <LoadingButton
                        variant="outlined"
                        disabled={isFetchingGetBookings || isFetchingPullBookings}
                        loading={isLoadingPullBookings || isFetchingPullBookings}
                        onClick={onRefreshBookings}
                        padding="10px"
                    >
                        Sync
                    </LoadingButton>
                </Box>
            ) : (
                null
            )} */}
            {/*
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", flexWrap: "wrap" }}>
                {dataICalendar !== undefined ? (
                    <LoadingButton
                        variant="outlined"
                        disabled={isFetchingGetBookings || isFetchingPullBookings}
                        loading={isLoadingPullBookings || isFetchingPullBookings}
                        onClick={onRefreshBookings}
                    >
                        Sync
                    </LoadingButton>
                ) : (
                    null
                )}
                {(!isGettingICalendar && dataICalendar === undefined) ? (
                    <Button
                        variant="contained"
                        onClick={() => {
                            // setShowICalForm(true);
                        }}
                    >
                        Add
                    </Button>
                ) : (
                    null
                )}
            </Box>
            */}
        </Box>
    );
}
