import { Alert, Box, Button, Grid, IconButton, Skeleton, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, OpenInNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useGetPropertyQuery } from "../../features/auth/hostAPI";
import PropertyStatus from "./PropertyStatus";
import UpdatePropertyForm from "./UpdatePropertyForm";
import { showLoader } from "../../features/auth/authSlice";

const StyledPropertyDetail = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    "& .label": {
        color: theme.palette.text.secondary,
        flexShrink: 0,
        paddingTop: 4,
        paddingBottom: 4,
    },
    "& .value": {
        paddingLeft: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    "&:not(:first-of-type) .value": {
        paddingTop: theme.spacing(1),
    },

    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
    },
}));

export default function ViewProperty({ propertyId, RemoveProperty }) {
    /*
    const theme = useTheme();
    const dispatch = useDispatch();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    */
    const navigate = useNavigate();
    const [showPropertyForm, setShowPropertyForm] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const {
        data: dataGetProperty,
        isLoading: isLoadingGetProperty,
        isFetching: isFetchingGetProperty,
    } = useGetPropertyQuery({ propertyId });

    const callbackSuccess = () => {
        setShowSuccessAlert(true);
    };

    const viewData = () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {dataGetProperty ? (
                <Box>
                    <StyledPropertyDetail>
                        <Box className="label">Street Address:</Box>
                        <Button
                            endIcon={<OpenInNew />}
                            onClick={() => navigate(`/property/${propertyId}/update-details`)}
                        >
                            {dataGetProperty.streetAddress1}
                        </Button>
                    </StyledPropertyDetail>
                </Box>
            ) : (
                <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
            )}
        </Box>
    );
    return (
        <>
            {showSuccessAlert && (
                <Alert
                    sx={{ marginBottom: 2 }}
                    onClose={() => {
                        setShowSuccessAlert(false);
                    }}
                    severity="success"
                >
                    Basic information successfully updated.
                </Alert>
            )}
            {
                viewData()
            }
        </>
    );
}
/*
    return showPropertyForm ? (
        <UpdatePropertyForm loadedProperty={property} setShowPropertyForm={setShowPropertyForm} />
    ) : (
        viewData()
    );
*/
