import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, IconButton, Skeleton, Stack, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Menu, OpenInNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useGetBookingsQuery, useGetICalendarQuery, usePullBookingsMutation } from "../../features/auth/icalendarAPI";
import "../../utils/moment-setup";
import BookingCalendar from "./BookingCalendar";

function ViewSchedule({ propertyInfo, setShowICalForm }) {
    const navigate = useNavigate();
    const [showSuccessAlert, setShowSuccessAlert] = useState({
        open: false,
        status: "",
        message: "",
    });

    const {
        data: dataICalendar,
        isLoading: isLoadingICalendar,
        isFetching: isFetchingICalendar,
        refetch: refetchScheduleInfo,
    } = useGetICalendarQuery({
        propertyFk: propertyInfo.id,
    });

    const isGettingICalendar = isLoadingICalendar || isFetchingICalendar;

    const {
        data: dataGetBookings,
        refetch: refetchGetBookings,
        isLoading: isLoadingGetBookings,
        isFetching: isFetchingGetBookings,
        error: errorGetBookings,
    } = useGetBookingsQuery({ iCalendarFK: dataICalendar?.id }, { skip: !dataICalendar });

    useEffect(() => {
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: false,
            status: "",
            message: "",
        }));
    }, []);

    const onUpdateICalendar = (data) => {
        if (data.status === "success") {
            setShowICalForm(false);
        }
        // setShowSuccessAlert(true);
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: true,
            status: data.status,
            message: data.message,
        }));
    };

    const handleUpdateOnClick = () => {
        setShowICalForm(true);
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: false,
        }));
    };

    return (
        <>
            {(showSuccessAlert.open) && (
                <Alert
                    sx={{ marginBottom: 2 }}
                    severity={showSuccessAlert.status}
                    onClose={() => {
                        setShowSuccessAlert((prev) => ({
                            ...prev,
                            open: false,
                        }));
                    }}
                >
                    {showSuccessAlert.message}
                </Alert>
            )}
            {dataICalendar !== undefined ? (
                <BookingCalendar
                    bookings={dataGetBookings}
                    isLoading={isLoadingGetBookings || isFetchingGetBookings}
                    error={errorGetBookings}
                    propertyId={propertyInfo.id}
                    iCalendarFK={dataICalendar.id}
                />
            ) : !isFetchingICalendar ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Button
                        endIcon={<OpenInNew />}
                        onClick={() => navigate(`/property/${propertyInfo.id}/update-schedule`)}
                    >
                        No iCalendar yet
                    </Button>
                </Box>
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="rounded" height={400} />
                </Stack>
            )}
        </>
    );
}

export default ViewSchedule;
