import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Alert, Box, Button, Grid, IconButton, Skeleton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Menu, OpenInNew } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import BookingCalendarBase from "./BookingCalendarBase";
import { useGetICalendarQuery, usePullBookingsMutation } from "../../features/auth/icalendarAPI";

export default function BookingCalendar({ bookings, isLoading, error, propertyId, iCalendarFK }) {
    const navigate = useNavigate();

    const [pullBookings, {
        // data: dataPullBookings,
        isLoading: isLoadingPullBookings,
        isFetching: isFetchingPullBookings,
        error: errorPullBookings,
    }] = usePullBookingsMutation();

    const {
        data: dataICalendar,
        isLoading: isLoadingICalendar,
        isFetching: isFetchingICalendar,
        refetch: refetchScheduleInfo,
    } = useGetICalendarQuery({
        propertyFk: propertyId,
    });

    const onRefreshBookings = async () => {
        await pullBookings({ iCalendarFK }).unwrap();
    };

    if (!isLoading) {
        return (
            <Grid container spacing={1}>
                {/* {showSuccessAlert && (
                    <Grid item xs={12}>
                        <Alert onClose={() => setShowSuccessAlert(false)}>Bookings refreshed.</Alert>
                    </Grid>
                )} */}
                <Grid item xs={12}>
                    {error && (
                        <Alert severity="error">An error occured.</Alert>
                    )}
                    {!error && bookings && bookings.length === 0 && (
                        <Box display="flex" gap={1} alignItems="center">
                            {/* <Alert severity="info" sx={{ flexGrow: 1 }}>No bookings yet.</Alert> */}
                            {/* <Box>
                                <LoadingButton
                                    variant="outlined"
                                    disabled={isFetchingPullBookings}
                                    loading={isLoadingPullBookings || isFetchingPullBookings}
                                    onClick={onRefreshBookings}
                                >
                                    Sync
                                </LoadingButton>
                            </Box> */}
                            <Button
                                endIcon={<OpenInNew />}
                                onClick={() => navigate(`/property/${propertyId}/update-schedule`)}
                            >
                                No bookings yet.
                            </Button>
                        </Box>
                    )}
                    {!error && bookings && bookings.length !== 0 && (
                        <BookingCalendarBase bookings={bookings} propertyId={propertyId} />
                    )}
                </Grid>
            </Grid>
        );
    }
    return (
        <Stack spacing={1}>
            <Skeleton variant="rounded" height={400} />
        </Stack>
    );
}
