import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { useGetPropertyQuery } from "../../features/auth/hostAPI";
import UpdatePropertyForm from "./UpdatePropertyForm";

export default function UpdatePropertyPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        data: dataGetProperty,
        isLoading: isLoadingGetProperty,
        isFetching: isFetchingGetProperty,
    } = useGetPropertyQuery({ propertyId: id });

    return (
        <Card>
            {
                dataGetProperty ? (
                    <>
                        <CardHeader title={`Update Property Details for ${dataGetProperty.propertyName}`} />
                        <CardContent>
                            <UpdatePropertyForm
                                loadedProperty={dataGetProperty}
                                callbackSuccess={() => {
                                    navigate(`/property/${id}`);
                                }}
                                setShowPropertyForm={(willShow) => {
                                    if (willShow) {
                                        navigate(`/property/${id}/update-details`);
                                    } else {
                                        navigate(`/property/${id}`);
                                    }
                                }}
                            />
                        </CardContent>
                    </>
                ) : (
                    <>
                        <CardHeader title={(
                            <Skeleton
                                variant="rectangular"
                                sx={{ fontSize: "2rem" }}
                            />
                        )}
                        />
                        <CardContent>
                            <Skeleton
                                variant="rectangular"
                                height={400}
                            />
                        </CardContent>
                    </>
                )
            }
        </Card>
    );
}
