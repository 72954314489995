import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import RulesPage from "./RulesPage";

function UpdateRulesPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <Card>
            <CardContent>
                <Typography variant="h5">Add/Update Alerts</Typography>
                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                    <Grid item xs={12}>
                        <RulesPage propertyId={id} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ float: "right" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                navigate(`/property/${id}`);
                            }}
                        >
                            Go Back
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default UpdateRulesPage;
